<template>
  <section class="section" :class="bg" id="features">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <div class="feature-detail">
            <div class="title-box">
              <h2
                class="fadeIn animated wow"
                :class="text"
                data-wow-delay=".1s"
              >{{$t("Backend development")}}</h2>
              <div class="border"></div>
            </div>

            <p
              :class="text"
            >{{$t("Kurlabs has a large backend development team, with experience in the development of high-level computing platforms and with high concurrency support.")}}</p>

            <p
              :class="text"
            >{{$t("To achieve this goal, Kurlabs has experts in Django, a Web framework with Python programming language and Go experts, a programming language created by google which is used for the development of high concurrency microservices.")}}</p>

            <p
              :class="text"
            >{{$t("These systems are integrated into modern, relational databases such as MySql, Postgres and non-relational databases such as MongoDB.")}}</p>
            <p
              :class="text"
            >{{$t("Additionally, the team has experience in connections to any external service or platform via API.")}}</p>
            <div class="row align-items-center">
              <div class="col-3">
                <img
                  src="../../assets/images/technologies/postgres.png"
                  alt="postgres"
                  class="img-icon"
                />
              </div>
              <div class="col-3">
                <img
                  src="../../assets/images/technologies/MongoDb.png"
                  alt="MongoDB"
                  class="img-icon"
                />
              </div>
              <div class="col-3">
                <img
                  src="../../assets/images/technologies/python.png"
                  alt="python"
                  class="img-icon"
                />
              </div>
              <div class="col-3">
                <img src="../../assets/images/technologies/go.png" alt="go" class="img-icon" />
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6" :class="order">
          <img
            src="../../assets/images/technologies/backend.jpg"
            class="img-fluid fadeIn animated wow center-block"
            data-wow-delay=".2s"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "BackEnd",
  props: {
    bg: {
      default: "bg-darkslategray",
      type: String
    },
    text: {
      default: "text-white",
      type: String
    },
    order: {
      default: "",
      type: String
    }
  }
};
</script>

<style scoped>
</style>