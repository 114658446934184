<template>
  <div>
    <Home />
    <Initial />
    <ManageControl />
    <BackEnd bg="bg-gray" text=" " order="order-first" />
    <FrontEnd order="order-last" />
    <Alerty />

    <Services />
    <Comments />
    <ContactUs />
  </div>
</template>

<script>
import Home from "./Home.vue";
import Initial from "./Initial.vue";
import Services from "./Services.vue";
import Comments from "./Comments.vue";
import ContactUs from "./ContactUs.vue";
import ManageControl from "../infrastructure/ManageControl.vue";
import Alerty from "../infrastructure/Alerty";
import BackEnd from "../development/BackEnd.vue";
import FrontEnd from "../development/FrontEnd";

export default {
  name: "LandingIndex",
  components: {
    Home,
    Initial,
    Services,
    Comments,
    ContactUs,
    ManageControl,
    BackEnd,
    FrontEnd,
    Alerty
  }
};
</script>
